import {axiosGet,axiosPost,axiosPostJson,axiosUpload} from '@/utils/request'

export function getTotal(data){
    return  axiosPostJson('im-server/session/getUnreadTotal',data) 
}
export function getTic(data){
    return axiosGet('zucenter-server/companyInfo/getCloneTicket',data)
}
//获取权限
export function getuserRole(data){
    return axiosGet('zucenter-server/roleAndPower/company/getSelfRoles',data)
} 
// 登录
export function login(data){
    return  axiosPostJson('zucenter-server/companyInfo/login',data) 
}
// 退出登录
export function logout(data){
    return axiosGet('zucenter-server/companyInfo/logout',data)
}
// 获取验证码  测试环境验证码直接输 111222
export function getSmsCode(data){
    return axiosGet('zucenter-server/user/getSmsCode',data)
}
// 获取图片验证码
export function getImageCode(data){
    return axiosGet('zucenter-server/user/getImageCode',data)
}

// 忘记密码后重置密码
export function resetPwd(data){
    return  axiosPostJson('zucenter-server/user/resetPassword',data) 
}

// -----------------------------------------

// 首页
// 基础数据
export function getBasicData(data){     
    return axiosGet('stat-server/userAndOrder/company/getHomeUserAndOrderStat',data)
} 
// 会话数据
export function getGzFwzData(data){    // 雇主服务者在线数
    return axiosGet('stat-server/onlineStat/queryMaxNumByUserId',data)
} 
export function getMsgOrderData(data){     // 消息数和订单转化 +会话数
    return axiosGet('stat-server/onlineStat/queryMsgTotal',data)
} 
export function getHomeCate(data){     // 消息数和订单转化 
    return axiosGet('zproduct-server/cateLib/getCateList',data)
}  
export function getCircleGraph(data){     // 服务事项类型占比
    return axiosGet('stat-server/userAndOrder/company/getHomeOrderStatByCate',data)
}  
export function getHomeOrderTrend(data){     // 订单趋势
    return axiosGet('stat-server/userAndOrder/company/getHomeOrderChart',data)
} 
export function getHomeList(data){     // 目标完成情况，查看全部
    return axiosGet('zorder-server/achievementGoal/company/getHomeList',data)
} 

// 资料设置

// 详情
export function getCompany(data){
    return axiosGet('zucenter-server/companyInfo/company/getCompanyDetail',data)
}
// 上传文件  
export function Upload(data){
    return axiosUpload('zcommon-server/file/upload',data)
}  
// 设置头像
export function setLogo(data){
    return axiosGet('zucenter-server/companyInfo/company/setLogo',data)
}

// 编辑发票寄送地址
export function setInvoice(data){
    return axiosGet('zucenter-server/companyInfo/company/setExpAddress',data)
}

// 原密码修改密码
export function changePass(data){
    return axiosGet('zucenter-server/companyInfo/modifyPassword',data)
}
export function getUrl(data){
    return axiosGet('payment-server/payment/system/oauth/getUrl',data)
}

// --------------------------------------
//客服管理
export function areaList(data){
    return axiosGet('zcommon-server/addr/getAllAddrTree',data)
}
// 列表
export function serverList(data){
    return axiosGet('zucenter-server/companyUser/company/getUserList',data)
}
// 添加客服
export function addServer(data){
    return  axiosPostJson('zucenter-server/companyUser/company/addUser',data) 
}
// 添加客服-服务品类
export function getServerOne(data){
    return axiosGet('zproduct-server/cateLib/getCateListLevelOne',data)
}
// 重新邀请
export function getInviteUrl(data){
    return  axiosGet('zucenter-server/companyUser/company/getInviteUrl',data) 
} 
// -发送短信
export function sendSms(data){
    return  axiosPostJson('zucenter-server/companyUser/company/sendSms',data) 
}
// 是否推单
export function setUserOrder(data){
    return  axiosGet('zucenter-server/companyUser/company/setUserOrderSwitch',data) 
}
// 操作删除
export function deleteUser(data){
    return  axiosGet('zucenter-server/companyUser/company/deleteUser',data) 
}

// 客服管理-基本信息
export function getServerDetail(data){
    return axiosGet('zucenter-server/companyUser/company/getKfDetail',data)
}

//服务者详情-钱包-收入明细
export function getSettleList(data){
    return axiosGet('zorder-server/wallet/platform/getWalletInList',data) 
}
//服务者详情-钱包-收入明细-备注
export function getwalletListDetail(data){
    // return axiosGet('zorder-server/settleInfo/company/getSettleOrderList',data)
    return axiosGet('zorder-server/wallet/company/getSettleDetail',data)
} 
//服务者详情-钱包-退款明细
export function getRefundList(data){
    return axiosGet('zorder-server/wallet/platform/getRefundList',data) 
}
// 冻结/解冻
export function getThakList(data){
    return axiosGet('zorder-server/wallet/platform/getWalletFreezeList',data) 
} 
export function getappealOrRefundList(data){
    return axiosGet('zorder-server/refund/company/getList',data)
}

//客服管理-订单
export function getOrderList(data){
    return axiosGet('zorder-server/orderInfo/company/getOrderList',data)
}
//客服管理-服务
export function getCateList(data){
    return axiosGet('zucenter-server/userProductCate/company/getFullCateTreeOfUser',data)
}
//客服管理-服务-详情
export function getDetail(data){
    return axiosGet('zproduct-server/userProduct/company/getUserProductPreview',data)
}

//订单

// 申请结算弹框
export let getTobeSettle = (data) => axiosGet("zorder-server/settleInfo/company/getTobeSettleByMonth", data);

// 申请结算确认
export let applySettle = (data) => axiosPostJson("zorder-server/settleInfo/company/applySettle", data);

// 订单详情
export function getOrderDetail(data){
    return axiosGet('zorder-server/orderInfo/company/getOrderDetail',data)
}
//根据订单id 和日期获取 计划区间
export let getOrderScope = (data) => axiosGet("zorder-server/dingban/user/getBlocksOfDay", data);

//根日期获取详情
export let getOrderDateDetail = (data) => axiosGet("zorder-server/dingban/user/getBlockContent", data);

// 退款详情  申诉详情
export let getrefundDetail = (data) => axiosGet("zorder-server/refund/company/getDetail", data);
export let getReasonSelect=(data)=> axiosGet('zorder-server/refund/platform/getReasonList',data)

// 查看群聊 
export function getMsgRecord(data){
    return axiosGet('im-server/session/getMsgRecord',data)
}
// 查看群聊基本信息
export function getMsgBasic(data){
    return axiosGet('zorder-server/orderInfo/company/getOrderBrief',data)
} 
// 佣金管理
// 商品tree
export function productTree(data){
    return axiosGet('zproduct-server/cateLib/getCateList',data)
}
// 商品-右侧table
export function productTabs(data){
    return axiosGet('zproduct-server/comProduct/company/getProductListByCateId3',data)
}
// 修改单个客服佣金比例
export function setRate(data){
    return axiosGet('zucenter-server/companyContract/company/setUserProductRate',data)
}
export function addCateToUser(data){
  return  axiosPostJson('zucenter-server/userProductCate/company/addCateToUser',data)
}


// -------------------------------------------
// 结算
//订单明细
export function getSettleListDetail(data){
    return axiosGet('zorder-server/settleInfo/company/getSettleOrderList',data)
}
export function getSettlementList(data){
    return axiosGet('zorder-server/settleInfo/company/getSettleList',data)
}
// // 结算-编辑开票
export function getOpenInvoice(data){
    return axiosGet('zhiniu-server/systemConfig/platform/getOpenInvoice',data)
}
// 开票提交
export function addInvoice(data){
    return axiosPostJson('zorder-server/settleInfo/company/addInvoice',data)
} 
export function getSettleDetail(data){
    return axiosGet('zorder-server/settleInfo/company/getSettleDetail',data)
} 

// ------------------------------------------
// 目标-列表
export function getAimList(data){
    return axiosGet('zorder-server/achievementGoal/company/getList',data)
}
export function handleEdit(data){
    return axiosGet('zorder-server/achievementGoal/company/getGoalEditPreview',data)
}
// 编辑保存
export function EditSave(data){
    return axiosGet('zorder-server/achievementGoal/company/setGoal',data)
}

// ------------------------------------------------
// 学习中心- 类别接口
export function studyAll(data){
    return axiosGet('zhiniu-server/study-cate/all',data)
}

export function studyList(data){
    return  axiosPostJson('zhiniu-server/study-learning/list',data) 
}
export function studyDetail(data){
    return axiosGet('zhiniu-server/study-learning',data)
}

// 权限配置
export function getConfigRoleList(data){
    return axiosGet('zucenter-server/roleAndPower/company/role/getRoleList',data)
}   
export function addAcc(data){
    return  axiosPostJson('zucenter-server/roleAndPower/company/subAccount/add',data) 
} 
export function updateAcc(data){
    return  axiosPostJson('zucenter-server/roleAndPower/company/subAccount/updateDetail',data) 
}  
export function getConfigList(data){
    return axiosGet('zucenter-server/roleAndPower/company/subAccount/getList',data)
} 
export function setConfigEdit(data){
    return axiosGet('zucenter-server/roleAndPower/company/subAccount/getDetail',data)
}     
export function setConfigDel(data){
    return axiosGet('zucenter-server/roleAndPower/company/subAccount/delete',data)
}

