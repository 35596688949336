import axios from "axios"
import qs from "qs";
import router from "../router/index"
import { Loading, Message } from 'element-ui'
function showMsg(msgInfo, type, time) {
    Message.closeAll();
    Message({
        type: type || "success",
        showClose: false,
        dangerouslyUseHTMLString: true,
        message: msgInfo,
        duration: time || 3000,
    });
}


axios.defaults.baseURL = process.env.VUE_APP_URL
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;


// 设置请求拦截器
axios.interceptors.request.use(function (config) {

    return config;
}, function (error) {
    return Promise.reject(error);
})


// 设置响应拦截器
axios.interceptors.response.use(function (response) {
    //接口返回 失败后的消息提示
    let _data = response.data;
    if (_data.code == 400005 || _data.code == 400000 || _data.code == 500000 || _data.code == 400100) {
        showMsg(_data.message, "error", 3000);
    } else if (_data.code == 400002||_data.code == 400006) {
        if (!_data.message.includes('登录异常')) {
            showMsg(_data.message, "error", 3000);
        }
        router.push('/login')
    }
    return response;

}, function (error) {
 
    showMsg(error.message,'error', 3000);
    return Promise.reject(error);
})



export default axios;
export let axiosGet = (url, data = "") => {
    let _data = JSON.parse(JSON.stringify(data))

    if (typeof _data == 'object') {
        for (const key in _data) {
            if (_data[key] === '') {
                delete _data[key]
            }
        }
    }
    return axios.get(url, { params: _data });
};

export let axiosPost = (url, data = "") => {
    return axios.post(url, qs.stringify(data));
};

export let axiosPostJson = (url, data = "") => {
    let _data = JSON.parse(JSON.stringify(data))

    if (typeof _data == 'object') {
        for (const key in _data) {
            if (_data[key] === '' || _data[key] === null) {
                delete _data[key]
            }
        }
    }

    return axios.post(url, JSON.stringify(_data), { headers: { "Content-Type": "application/json" } });
};
export let axiosUpload = (url, data = "") => {
    let files = new FormData()
    files.append('file', data)
    return axios.post(url, files, { headers: { "Content-Type": "multipart/form-data" }, });
};
