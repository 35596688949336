import Vue from 'vue'
import Vuex from 'vuex'
import ourwebsocket from "@/assets/js/socket";
import { getTotal } from '../utils/api'
let urls=process.env.VUE_APP_WEB_SOCKET

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 登录用户信息
    userinfo: {},
    websock: "",
    //系统消息  
    userId: JSON.parse(sessionStorage.getItem("loginInfo"))?.userId,
  },
  mutations: {
    saveUserinfo(state, parmas) { //保存用户信息
      state.userinfo = parmas 
      sessionStorage.setItem("loginInfo", JSON.stringify(state.userinfo));
      sessionStorage.setItem("headImg",state.userinfo.headImg)  
    },
    websocketInit(state,parmas) {
      state.websock = new ourwebsocket(urls);
      state.websock.initWebSocket(dat => {
        console.log("返回数据");
        console.log(dat);
        if (dat.body) {
          let _data = dat;
          let json = dat.body.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2')
          _data.body = JSON.parse(json);
          console.log("返回body数据");
          console.log(_data);
          console.log(_data.body);   
        }
      });
    },
  },
  actions: {
  },
  getters: {
  },
  modules: {
  }
})
